import React from "react"
import { PageProps, graphql } from "gatsby"

import { Query } from "@graphql-types"
import { Header, SEO } from "@global"
import ResourcesGrid from "@components/resources/resourcesGrid"

interface Props extends PageProps {
  data: Query
}

const TestimonialsPage = ({ data }: Props) => {
  if (data == null || data.sanityResourcesPage == null) return null

  const { title, seo, resources } = data.sanityResourcesPage

  return (
    <div className="subpage-container">
      {seo && <SEO data={seo} />}
      <Header title={title} />
      <ResourcesGrid data={resources} />
    </div>
  )
}

export default TestimonialsPage

export const query = graphql`
  {
    sanityResourcesPage {
      title
      seo {
        ...sanitySeo
      }
      resources {
        _key
        _type
        title
        description
        category
        link {
          ...sanityLink
        }
        icon {
          ...sanityFlightImage
        }
      }
    }
  }
`
